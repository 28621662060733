//types
import { pipe, Lazy } from 'fp-ts/function';
import { UserStatuses } from '@domain/auth/types';
import * as T from 'fp-ts/lib/Task';
import * as O from 'fp-ts/lib/Option';
import { switchCase } from '@utils/fp-ts';
import { checkUserStatus } from './api';
import { Deps } from '@domain/auth';

// repositories
import {
  resendActivationEmailPipe,
  getUsernameUsedForLoginPipe,
} from '@repositories/auth/pipelines';

const userStatusHandler = ({ purgeNotifications, addError }: Deps) => (
  message: string
) => {
  purgeNotifications();
  addError(
    // Please keep in sync with the backend string. It has the
    // same key.
    message
  );
};

const getUserStatusHandlers = (
  deps: Deps
): Record<UserStatuses, Lazy<void>> => ({
  // If the user account is not activated or locked, let the user know.
  STAGED: () => {
    resendActivationEmailPipe(deps)({
      variables: {
        email: getUsernameUsedForLoginPipe(deps.usernameUsedForLogin),
        langcode: deps.i18n.language,
      },
    })();
    userStatusHandler(deps)(
      deps.t(
        'okta-validation-error.pending-account-resend',
        'Your account is not yet activated. We just resend the activation message to you, please check your email.'
      )
    );
  },
  LOCKED_OUT: () =>
    userStatusHandler(deps)(
      deps.t(
        'okta-validation-error.locked-account',
        'Your account is locked. Please follow the steps for <a href="/reset-password">forgot password</a> to unlock your account and reset your password.'
      )
    ),
  PROVISIONED: () => {},
  ACTIVE: () => {
    // If Okta returns an error and account status is ACTIVE, then password is incorrect.
    userStatusHandler(deps)(
      deps.t(
        'okta-validation-error.incorrect-password',
        'The email address and password combination is incorrect.'
      )
    );
  },
  RECOVERY: () => {},
  PASSWORD_EXPIRED: () => {},
  SUSPENDED: () => {},
  DEPROVISIONED: () => {},
  NOT_FOUND: () => {
    userStatusHandler(deps)(
      deps.t(
        'okta-validation-error.user-not-found',
        'No account is associated with this email address. Please try a different email address or register for a new account.'
      )
    );
  },
  UNKNOWN_STATUS: () => {
    userStatusHandler(deps)(
      deps.t(
        'okta-validation-error.internal-server-error',
        'Your attempt to sign in failed because of a software problem. Please try again later or <a href="https://my.rotary.org/en/contact">contact us</a> for help.'
      )
    );
  },
});

export const prepHandleSignInError = (deps: Deps) => (
  context: { controller: string },
  error: {
    name: string;
    message: string;
    statusCode?: number;
    xhr?: object;
  }
) => {
  if (
    O.isSome(deps.usernameUsedForLogin.read()) &&
    error.name === 'AuthApiError' &&
    error.statusCode === 401
  ) {
    pipe(
      checkUserStatus(getUsernameUsedForLoginPipe(deps.usernameUsedForLogin))(),
      T.map((response: UserStatuses) => {
        switchCase(response, getUserStatusHandlers(deps), () => {});
      })
    )();
  }
};
