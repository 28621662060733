import { pipe } from 'fp-ts/function';
import * as IO from 'fp-ts/lib/IO';
import * as T from 'fp-ts/lib/Task';

import { curry } from '@utils/fp-ts/curry';

import * as Drupal from '../utils/dataFetching/drupalFetcher';
import { Lang } from '@domain/language-negotiation';
import { Destination } from '@domain/auth';

import { addLocalisationToPath } from '@utils/localized-navigate';

export type I18n = {
  language: Lang;
};

type ValidDestination = {
  valid_path: number;
  external_path: number;
  redirect: string;
};
// checkExternalDestinationCurried :: Env, Lang, Destination -> AxiosResponse
export const checkExternalDestinationCurried = curry(
  ({ language }: I18n, destination: Destination): T.Task<string> =>
    pipe(
      `${language}/restapi/check_destination?path=${destination}`,
      Drupal.get,
      IO.map(
        T.map((validDestination: ValidDestination) => {
          // If the destination is not valid, then we just show a notification to
          // the user and redirect the user to the frontpage.
          if (!validDestination.valid_path) {
            const finalDestination = addLocalisationToPath('/');
            // We want to log this failure, thus throwing
            throw { ...validDestination, destination: finalDestination };
          }
          // If we get a valid redirect back, then use it for our destination.
          if (validDestination.valid_path && validDestination.redirect) {
            return validDestination.redirect;
          }
          return destination;
        })
      )
    )()
);
