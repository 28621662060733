export namespace Okta {
  // As returned by Okta's /v1/userinfo endpoint.
  export interface UserInfo {
    // Okta user ID
    sub: string;
    // Okta login email.
    email: string;
    email_verified: boolean;
  }

  export enum OktaUserStatus {
    Active = 'ACTIVE',
    Staged = 'STAGED',
  }

  // As returned by Okta's /api/v1/users/{id-or-email} endpoint.
  // https://developer.okta.com/docs/reference/api/users/#user-object
  // Only used properties are defined.
  export interface Account {
    id: string;
    status:
      | 'STAGED'
      | 'PROVISIONED'
      | 'ACTIVE'
      | 'RECOVERY'
      | 'LOCKED_OUT'
      | 'PASSWORD_EXPIRED'
      | 'SUSPENDED'
      | 'DEPROVISIONED';
    profile: UserProfile;
    dominoIndividualId: string;
    credentials: {
      recovery_question?: {
        question?: string;
      };
    };
  }

  // Mock endpoints return extended account containing credentials. Use this
  // type carefully because real Okta returns only properties from the Account
  // type.
  export interface AccountInternal extends Account {
    credentials: {
      password?: {
        value?: string;
      };
      recovery_question?: {
        question?: string;
        answer?: string;
      };
    };
  }

  export interface UserProfile {
    email: string;
    login: string;
    firstName: string;
    lastName: string;
    activationToken?: string;
    individualId?: string;
    // Net Forum key.
    USERGUID?: string;
    // RiIndividualId.
    MemberID?: string;
    manager?: string;
    mobilePhone?: string;
    secondEmail?: string;
    title?: string;
    department?: string;
    employeeNumber?: string;
    // "1" for true, "0" or empty string for false.
    youthFlag?: string;
    yearOfBirth?: string;
  }

  export interface Question {
    question: string;
    questionText: string;
  }
}

export interface Session {
  name_id: string;
  sessions_index: string;
  oktaData: Okta.Account;
  attributes: {
    [k: string]: any;
  };
}

export enum OktaTokenErrors {
  JWT_ISSUED_IN_FUTURE = 'The JWT was issued in the future',
}
