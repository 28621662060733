import { TFunction } from 'i18next';

import { addLocalisationToPath } from '@utils/localized-navigate';

import { SUPPLIER_PORTAL_URL } from '@constants/index';

import { Status } from '@typings/express-responses';

export const getMessageByLoginStatus = (t: TFunction, status: Status) => {
  switch (status) {
    case Status.DATE_INCOMPATIBLE:
      return t(
        'auth.login.date-incompatible-message',
        `Sorry, your local machine datetime is incompatible, please try again`
      );
    case Status.RENEW_ACCOUNT:
      return t(
        'auth.login.renew-message',
        `We're sorry, that account is a Supplier Portal account and cannot be used to access My Rotary.
          If you wish to access the Supplier Portal, please visit <a href="{{portalUrl}}">Supplier Portal</a>.
          If you want to use My Rotary and have a My Rotary account please use it to sign in.
          You may also <a href="{{registerUrl}}">register</a> for a My Rotary account if you do not yet have one.`,
        {
          portalUrl: SUPPLIER_PORTAL_URL,
          registerUrl: addLocalisationToPath('/signup'),
        }
      );
    case Status.CANNOT_AUTH_USER:
      return t(
        'okta-validation-error.internal-server-error',
        'Your attempt to sign in failed because of a software problem. Please try again later or <a href="https://my.rotary.org/en/contact">contact us</a> for help.'
      );
    case Status.AUTH_CONTROLLER_ERROR:
      return t(
        'okta-validation-error.internal-server-error',
        'Your attempt to sign in failed because of a software problem. Please try again later or <a href="https://my.rotary.org/en/contact">contact us</a> for help.'
      );

    default:
      return undefined;
  }
};
