import { pipe } from 'fp-ts/function';
import * as T from 'fp-ts/lib/Task';
import { checkServerDate } from '@repositories/auth/authExternalApps';
import { ClientServerDate } from '@typings/express-responses';

export const compareClientServerTime = (
  date: string
): Promise<{
  data: ClientServerDate;
}> =>
  pipe(T.bindTo('data')(checkServerDate(date)() as T.Task<ClientServerDate>))();
