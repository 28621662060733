import React, { useEffect, useRef } from 'react';

import { OktaError, SuccessData } from '@domain/auth';

import { getUsernameUsedForLoginPipe } from '@repositories/auth/pipelines';

import { useAppConfig } from '@hooks/appConfig';
import { useLogger } from '@hooks/logger';

import { LogLevel } from '@typings/operations';

type OktaSignInWidgetProps = {
  onAfterRender: () => void;
  onError: (context: { controller: string }, error: OktaError) => void;
  onSuccess: (data: SuccessData, renderFn: () => void) => Promise<void>;
};

const OktaSignInWidget = ({
  onSuccess,
  onError,
  onAfterRender,
}: OktaSignInWidgetProps) => {
  const {
    oktaWidget: { oktaWidget, usernameUsedForLogin },
  } = useAppConfig();
  const { addLog } = useLogger();

  const widgetRef = useRef<HTMLDivElement>(null);

  const renderSignInWidget = () => {
    if (widgetRef.current && oktaWidget) {
      /*
        afterError handler listens for external errors such as network errors etc.
        unlike catch handler which only covers okta widget errors such as config, browser or DOM errors and doesn't throw an error when the request fails.
      */
      oktaWidget
        .showSignInToGetTokens({
          el: widgetRef.current,
        })
        .then(handleSuccess)
        .catch(handleError);

      oktaWidget.on('afterError', handleAfterError);
      oktaWidget.on('afterRender', onAfterRender);
    }
  };

  const reRenderSignInWidget = () => {
    oktaWidget?.remove();
    renderSignInWidget();
  };

  const handleSuccess = (data: SuccessData) => {
    onSuccess(data, reRenderSignInWidget);
  };

  const handleAfterError = (
    context: { controller: string },
    error: OktaError
  ) => {
    addLog({
      level: LogLevel.Error,
      message: `showSignInToGetTokens: Okta error. Email: ${getUsernameUsedForLoginPipe(
        usernameUsedForLogin
      )}`,
      other: JSON.stringify(error),
    });

    onError(context, error);
  };

  const handleError = (error: string) => {
    addLog({
      level: LogLevel.Error,
      message: `showSignInToGetTokens: Okta widget internal error: ${error}. Email: ${getUsernameUsedForLoginPipe(
        usernameUsedForLogin
      )}`,
    });
  };

  useEffect(() => {
    renderSignInWidget();
    return () => oktaWidget?.remove();
  }, [oktaWidget]);

  return (
    <div data-testid="sign-in-widget" id="sign-in-widget" ref={widgetRef} />
  );
};

export default OktaSignInWidget;
