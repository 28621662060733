import { TFunction } from 'i18next';
import { ErrorMessages } from '@domain/auth/types';

export const getLoginErrorMessages = (t: TFunction): ErrorMessages => ({
  loginFailed: t(
    'user.login.failMessage',
    'We could not log you into our backend app.'
  ),
  missingAccessToken: t(
    'user.login.missingAccessToken',
    'The access token is missing.'
  ),
  invalidDestination: t(
    'user.login.invalidDestination',
    'We are sorry, but the specified destination is not one we can take you to immediately after login.'
  ),
});
