import React from 'react';
import Modal from 'react-modal';
import { Button } from '@components/Button';
import Icon from '@components/Icon';

const customStyles = {
  content: {
    maxWidth: '650px',
    maxHeight: '420px',
    margin: 'auto',
    padding: '45px',
    overflow: 'visible',
  },
};

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  title: string;
  text: string;
  continueButtonLabel: string;
}

const LoginModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  title,
  text,
  continueButtonLabel,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <Icon
        name="ui/close"
        color="#BEC4C9"
        className="absolute top-27px right-25px cursor-pointer w-6 h-6 desktop:w-8 desktop:h-8"
        clickHandler={closeModal}
      />
      <h2 className="mt-7 text-left break-words">{title}</h2>
      <p className="text-xs text-left">{text}</p>
      <div className="text-right mt-10 mb-12">
        <Button
          clickHandler={closeModal}
          className="mb-4 tablet:min-w-16 mobile-m:w-full"
          full
          secondary
        >
          {continueButtonLabel}
        </Button>
      </div>
    </Modal>
  );
};

export default LoginModal;
